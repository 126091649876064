<template>
  <v-card>
    <OrganicStructurePgHeader></OrganicStructurePgHeader>
    <section class="form-full-width">
      <FormOrganicStructurePg :loading="loading" :getOrganicStructurePg="getOrganicStructurePg" @beforeUpdate="updateOrganicStructurePg"></FormOrganicStructurePg>
    </section>
  </v-card>
</template>

<script>
import FormOrganicStructurePg from '@/components/forms/FormOrganicStructurePg.vue'
import OrganicStructurePgHeader from './OrganicStructurePgHeader.vue'
import useOrganicStructurePg from '@/composables/useOrganicStructurePg'

export default {
  components: {
    FormOrganicStructurePg,
    OrganicStructurePgHeader,
  },
  setup() {
    // Composition API
    const {
      organicStructurePg,
      loading,
      // methods
      getOrganicStructurePg,
      updateOrganicStructurePg,
    } = useOrganicStructurePg()

    return {
      organicStructurePg,
      loading,
      // methods
      getOrganicStructurePg,
      updateOrganicStructurePg,
    }
  },
}
</script>

<style lang="scss" scoped>
.form-full-width {
  padding: 10px;
}
</style>
