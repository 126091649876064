import {ref} from '@vue/composition-api';
import store from '@/store/index';

const useOrganicStructurePg = () => {
  // base data
  const organicStructurePg = ref (null);
  const loading = ref (false);

  // methods
  const getOrganicStructurePg = async () => {
    loading.value = true;
    const {data, status} = await store.dispatch ('fetchOrganicStructurePg');
    loading.value = false;
    if (status != 200) return;
    organicStructurePg.value = data;
    return data;
  };

  const updateOrganicStructurePg = async organicStructurePg => {
    loading.value = true;
    const {data, status} = await store.dispatch ('updateOrganicStructurePg', organicStructurePg);
    loading.value = false;
    if (status != 200 && status != 201) return;
    return data;
  };

  return {
    organicStructurePg,
    loading,
    // methods
    getOrganicStructurePg,
    updateOrganicStructurePg,
  };
};

export default useOrganicStructurePg;
